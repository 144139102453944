import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import DataTable from './datatable';

const Events = () => {

  const navigate = useNavigate();
  const [events, setEvents] = useState([])
  const divRef = useRef(null);
  const mainRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isResponsive, setIsResponsive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const checkNavbarVisibility = () => {
    const targetDiv = mainRef.current?.querySelector("#navId");
    const navbarState = targetDiv?.classList?.contains("noShowBar");
    setIsNavbarVisible(navbarState);
  };

  const handleVisibility = () => {
    const currentDivWidth = divRef.current?.offsetWidth;

    if (isNavbarVisible) {
      if (currentDivWidth === 1) {
        setIsVisible(true);
      } else if (currentDivWidth < window.innerWidth * 0.6) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      checkNavbarVisibility();
      handleVisibility();
    });

    const targetDiv = mainRef.current?.querySelector("#navId");
    if (targetDiv) {
      observer.observe(targetDiv, { attributes: true, attributeFilter: ["class"] });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    handleVisibility();
  }, [isNavbarVisible, windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const url = `/api/v1/events`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
    }).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((res) => {
        setEvents(res)
      })
      .catch(() => {
        navigate("/")
      });
}, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  }, [windowWidth]);

    const renderState = (state) => {
      if (state == "pending") {
        return <div className="state pending">En attente</div>
      } else if (state == "booked") {
        return <div className="state booked">Plannifié</div>
      } else if (state == "done") {
        return <div className="state done">Réalisé</div>
      } else if (state == "canceled") {
        return <div className="state canceled">Refusé</div>
      }
    }

    const columns = [
      {Header: "", accessor: "id",  disableFilters: true, show: false},
      {
        Header: "Date",
        accessor: "event_date",
        disableFilters: true,
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.event_date}</div>
      },
      {
        Header: "Dénomination du Client",
        accessor: "client_name",
        disableFilters: true,
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.client_name}</div>
      },
      {
        Header: "Nom de l’évènement",
        accessor: "name",
        disableFilters: true,
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.name}</div>
      },
      {
        Header: "Ville de réalisation",
        accessor: "city",
        disableFilters: true,
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.city}</div>
      },
      {
        Header: "Adresse de réalisation",
        accessor: "localisation",
        disableFilters: true,
        show: true,
        Cell: ({ row }) => <div onClick={() => navigate(`/events/${row.values.id}`)}>{row.values.localisation}</div>
      },
      {
        Header: "Statut",
        accessor: "state",
        disableFilters: true,
        show: true,
        Cell: ({ row }) => {
          return (
            <div onClick={() => navigate(`/events/${row.values.id}`)}>
              {renderState(row.values.state)}
            </div>
          )
        }
      },
    ]

    const listEvents = <DataTable columns={columns} data={events} search={false} s />

    const noEvent = (
      <div className="nocontent">
        <h4>
          Pas d'Évènements remontés
        </h4>
      </div>
    );

    return (
        <div ref={mainRef} className="main">
            <Navbar />
            <div ref={divRef}  className={`events work-space ${isResponsive && 'responsive'} ${
          ( isResponsive && (isVisible ? "visible" : "hidden"))
        } `}>
                <div className="header">
                </div>
                <div className="body">
                    <h2>Évènements</h2>
                    {events.length > 0 ? listEvents : noEvent}
                </div>
            </div>
        </div>
    )

};

export default Events;

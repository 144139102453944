import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Navbar from './navbar'
import Week from './week'
import PendingEvent from './pending_event'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faFileUpload, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import DocumentModal from './document_modal';
import ExpenseModal from './expense_modal';


const Dashboard = () => {

  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [weekNumber, setWeekNumber] = useState(0);
  const [refresh, setRefresh] = useState()
  const divRef = useRef(null);
  const mainRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isResponsive, setIsResponsive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const checkNavbarVisibility = () => {
    const targetDiv = mainRef.current?.querySelector("#navId");
    const navbarState = targetDiv?.classList?.contains("noShowBar");
    setIsNavbarVisible(navbarState);
  };

  const handleVisibility = () => {
    const currentDivWidth = divRef.current?.offsetWidth;

    if (isNavbarVisible) {
      if (currentDivWidth === 1) {
        setIsVisible(true);
      } else if (currentDivWidth < window.innerWidth * 0.6) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      checkNavbarVisibility();
      handleVisibility();
    });

    const targetDiv = mainRef.current?.querySelector("#navId");
    if (targetDiv) {
      observer.observe(targetDiv, { attributes: true, attributeFilter: ["class"] });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    handleVisibility();
  }, [isNavbarVisible, windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  }, [windowWidth]);


    useEffect(() => {
        const url = `/api/v1/technicians?week_number=${weekNumber}`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            setUser(res)
          })
          .catch(() => {
            navigate("/")
          });
    }, [weekNumber]);

    const manageEvent = (event, state) => {
      console.log(state)
      const url = `/api/v1/technicians/manage_event`;
      const data = {event: event, state: state}
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(data)
      }).then((res) => {
          if (res.ok) {
            return res.json();
          }
          //TO DO : handle error
          throw new Error("Network response was not ok.");
        })
        .then((res) => {
          setUser(res)
        })
        .catch(() => {
          navigate("/")
      });
    }

    const renderIcon = (icon, color, text) => {
      return (
        <OverlayTrigger trigger={["hover"]}
            overlay={
              <Tooltip>
               {text}
              </Tooltip>
            }
          >
          <Button className="shortcut" onClick={() => {navigate("/schedule")}}>
            <FontAwesomeIcon icon={icon} color={color} size="lg" pull="left" />
          </Button>
        </OverlayTrigger>
      )
    }

    return (
        <div ref={mainRef} className="main">
            <Navbar />
            <div ref={divRef} className={`dashboard work-space ${isResponsive && 'responsive'} ${( isResponsive && (isVisible ? "visible" : "hidden"))} `}>

                <div className="header">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
                <div className="body">

                    <div className="left">
                        <div className="accueil">
                            <h2>Bienvenue {user.first_name},</h2>
                            <p>Nous sommes ravis de t’accueillir dans ton espace dédié Soirs de Fêtes. Tu y retrouveras tes données et documents personnels pour lesquels nous comptons sur toi quant à leur tenue à jour …</p>
                            <p>Cet espace te sert également de centre de ressource dans lequel tu retrouveras <a href="/shared_documents">les documents communs Soirs de Fêtes</a>.</p>
                            <p>Si tu as besoin d’informations de notre part, n’hésite pas à nous contacter via <a href="mailto:contact@soirdefetes.com">contact@soirsdefetes.com</a> <br/>ou au 01 69 11 77 80.</p>
                            <p>A toi de jouer !</p>
                            <p>L’équipe Soirs de Fêtes </p>
                        </div>

                        <div className="shortcut-actions">
                            <h3>Actions rapides</h3>
                            <div className="shortcut-list">
                              <ExpenseModal setRefresh={setRefresh} mode={"dashboard"} />
                              {/* <DocumentModal setRefresh={setRefresh} mode={"dashboard"} />                                */}
                              {renderIcon(faCalendarCheck, "#FFF", "Gérer mes disponibilités")}
                            </div>
                        </div>
                    </div>

                    <div className="right">
                      <PendingEvent user={user} manageEvent={manageEvent} />
                      <Week user={user} weekNumber={weekNumber} setWeekNumber={setWeekNumber}/>
                      {/* events to come */}
                    </div>

                </div>
                <div className="footer">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
            </div>
        </div>
    )

};

export default Dashboard;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import BooleanInput from "./form/boolean_input";

const Schedule = () => {

  const navigate = useNavigate();
  const [technician, setTechnician] = useState({});
  const [refresh, setRefresh] = useState(false)
  const [schedule, setSchedule] = useState([])


  const divRef = useRef(null);
  const mainRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isResponsive, setIsResponsive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const checkNavbarVisibility = () => {
    const targetDiv = mainRef.current?.querySelector("#navId");
    const navbarState = targetDiv?.classList?.contains("noShowBar");
    setIsNavbarVisible(navbarState);
  };

  const handleVisibility = () => {
    const currentDivWidth = divRef.current?.offsetWidth;

    if (isNavbarVisible) {
      if (currentDivWidth === 1) {
        setIsVisible(true);
      } else if (currentDivWidth < window.innerWidth * 0.6) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      checkNavbarVisibility();
      handleVisibility();
    });

    const targetDiv = mainRef.current?.querySelector("#navId");
    if (targetDiv) {
      observer.observe(targetDiv, { attributes: true, attributeFilter: ["class"] });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    handleVisibility();
  }, [isNavbarVisible, windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  }, [windowWidth]);

    useEffect(() => {
        const url = `/api/v1/technicians`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
        }).then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((res) => {
            let data = Object.entries(res).filter(([key]) => key.includes('av_'))
            setTechnician(res)
            setSchedule(data)
          })
          .catch(() => {
            navigate("/")
          });
    }, []);

    useEffect(() => {
        if (technician.id) {
          const url = `/api/v1/technicians/${technician.id}`;
          const csrfToken = document.querySelector('meta[name="csrf-token"]').content
          const body = {technician}
          fetch(url, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken
            },
            body: JSON.stringify(body),
            credentials: "same-origin",
          }).then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw new Error("Network response was not ok.");
            })
            .then((res) => {
              setTechnician(res)
            })
            .catch(() => {
              navigate("/")
            });
        }
    }, [refresh]);

    // useEffect(() => {
    //   setTechnician( {...technician, schedule: schedule} )
    // }, [schedule]);

    const trad = (label) => {
      if (label == "av_mixt") {
        return "Disponible pour les autres entités"
      } else if (label == "av_week") {
        return "Disponible en semaine"
      } else if (label == "av_foreign") {
        return "Disponible pour l'étranger"
      } else if (label == "av_we_june") {
        return "Disponible les week-ends de juin"
      } else if (label == "av_we_august") {
        return "Disponible les week-ends d'aout"
      } else if (label == "av_we_december") {
        return "Disponible les week-ends de décembre"
      } else if (label == "av_1307") {
        return "Disponible le 13 juillet"
      } else if (label == "av_1407") {
        return "Disponible le 14 juillet"
      } else if (label == "av_1508") {
        return "Disponible le 15 aout"
      } else if (label == "av_3112") {
        return "Disponible le 31 décembre"
      }
    }

    return (
        <div ref={mainRef} className="main">
            <Navbar user={technician} />
            <div ref={divRef}  className={`expenses work-space ${isResponsive && 'responsive'} ${ ( isResponsive && (isVisible ? "visible" : "hidden"))}`}>
                <div className="header">
                </div>
                <div className="body">
                    <h2>Mes Disponibilités</h2>
                    <div className="form-container">
                      {
                        schedule.map((s, idx) => {
                          if (s[0] !== 'av_mixt') {
                            return <BooleanInput key={idx} editable={true} object={technician} setRefresh={setRefresh} setObject={setTechnician} slug={s[0]} title={trad(s[0])} />
                          }
                        })
                      }
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Schedule;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import Form from './form/profile_form'

const Profile = () => {

  const navigate = useNavigate();
  const [technician, setTechnician] = useState({});
  const [refresh, setRefresh] = useState(false)
  const divRef = useRef(null);
  const formRef = useRef(null);
  const mainRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isResponsive, setIsResponsive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const checkNavbarVisibility = () => {
    const targetDiv = mainRef.current?.querySelector("#navId");
    const navbarState = targetDiv?.classList?.contains("noShowBar");
    setIsNavbarVisible(navbarState);
  };

  const handleVisibility = () => {
    const currentDivWidth = divRef.current?.offsetWidth;

    if (isNavbarVisible) {
      if (currentDivWidth === 1 || currentDivWidth === 60) {
        setIsVisible(true);
      } else if (currentDivWidth < window.innerWidth * 0.6) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      checkNavbarVisibility();
      handleVisibility();
    });

    const targetDiv = mainRef.current?.querySelector("#navId");
    if (targetDiv) {
      observer.observe(targetDiv, { attributes: true, attributeFilter: ["class"] });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    handleVisibility();
  }, [isNavbarVisible, windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    const url = `/api/v1/technicians`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Network response was not ok.");
    })
      .then((res) => {
        setTechnician(res)
      })
      .catch(() => {
        navigate("/")
      });
  }, []);

  useEffect(() => {
    if (technician.id) {
      const url = `/api/v1/technicians/${technician.id}`;
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      const body = { technician }
      fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        body: JSON.stringify(body),
        credentials: "same-origin",
      }).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
        .then((res) => {
          setTechnician(res)
        })
        .catch(() => {
          navigate("/")
        });
    }
  }, [refresh]);



  return (
    <div ref={mainRef} className="main">
      <Navbar user={technician} />
      <div ref={divRef} className={`profile work-space ${isResponsive && 'responsive'} ${(isResponsive && (isVisible ? "visible" : "hidden"))}`}>
        <div className="header">
        </div>
        <div ref={formRef} className="body">
          <Form technician={technician} setTechnician={setTechnician} setRefresh={setRefresh} />
        </div>
      </div>
    </div>
  )

};

export default Profile;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./navbar";
import DataTable from "./datatable";
import DocumentModal from "./document_modal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Documents = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [refresh, setRefresh] = useState();
  const divRef = useRef(null);
  const mainRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isResponsive, setIsResponsive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const checkNavbarVisibility = () => {
    const targetDiv = mainRef.current?.querySelector("#navId");
    const navbarState = targetDiv?.classList?.contains("noShowBar");
    setIsNavbarVisible(navbarState);
  };

  const handleVisibility = () => {
    const currentDivWidth = divRef.current?.offsetWidth;

    if (isNavbarVisible) {
      if (currentDivWidth === 1) {
        setIsVisible(true);
      } else if (currentDivWidth < window.innerWidth * 0.6) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      checkNavbarVisibility();
      handleVisibility();
    });

    const targetDiv = mainRef.current?.querySelector("#navId");
    if (targetDiv) {
      observer.observe(targetDiv, { attributes: true, attributeFilter: ["class"] });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    handleVisibility();
  }, [isNavbarVisible, windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    const url = `/api/v1/documents`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      credentials: "same-origin",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((res) => {
        setDocuments(res);
      })
      .catch(() => {
        navigate("/");
      });
  }, [refresh]);

  const tradType = (doc_type) => {
    const docTypes = {
      vital: "Carte vitale",
      rib: "RIB",
      id: "Carte d'identité",
      passport: "Passeport",
      driving_licence: "Permis de conduire",
      degree: "Diplôme F4T2 niveau 1 ou 2",
      agreement: "Agrément préfectoral",
      caces: "CACES",
    };
    return docTypes[doc_type] || doc_type;
  };

  const handleDelete = (id) => {
    const url = `/api/v1/documents/${id}`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      credentials: "same-origin",
    }).then((res) => {
      if (res.ok) {
        const newList = documents.filter((item) => item.id !== id);
        setDocuments(newList);
      } else {
        throw new Error("Network response was not ok.");
      }
    });
  };

  const columns = [
    { Header: "", accessor: "id", disableFilters: true, show: false },
    {
      Header: "Type",
      accessor: "doc_type",
      disableFilters: true,
      show: true,
      Cell: ({ row }) => (
        <a href={row.values.sp_attachment_link} target="_blank" rel="noreferrer">
          {tradType(row.values.doc_type)}
        </a>
      ),
    },
    { Header: "Déposé le", accessor: "created_at", disableFilters: true, show: true },
    { Header: "", accessor: "sp_attachment_link", disableFilters: true, show: false },
  ];

  const listDocuments = <DataTable columns={columns} data={documents} search={false} />;
  const toolTip = (
    <Tooltip>
      Pour toute modification de documents, merci de l'envoyer à admin@contact.soirsdefetes.com
    </Tooltip>
  );

  const noDocument = (
    <div className="nocontent">
      <h4>Pas de documents remontés</h4>
    </div>
  );

  return (
    <div ref={mainRef} className="main">
      <Navbar />
      <div
        ref={divRef}
        className={`documents work-space ${isResponsive && "responsive"} ${
          ( isResponsive && (isVisible ? "visible" : "hidden"))
        }`}
      >
        <div className="header"></div>
        <div className="body">
          <div className="page-title">
            <h2>Documents</h2>
            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={toolTip}>
              <FontAwesomeIcon className="icon-details" icon={faQuestionCircle} color="#41A1D7" />
            </OverlayTrigger>
          </div>
          {documents.length > 0 ? listDocuments : noDocument}
        </div>
      </div>
    </div>
  );
};

export default Documents;

import React, { useEffect, useState } from 'react';

const FileInput = ({ editable, object, setObject, slug, title, options = [], setRefresh, isResponsive }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);


  const handleDocumentChange = (event) => {

    let file;
    file = isResponsive ? URL.createObjectURL(event.target.files[0]) : event.target.files[0]
    // const file = event.target.files[0];
    setSelectedDocument(file);
    setObject({ ...object, [slug]: file })
    setRefresh(`${slug}-${file}`)
  };

  return (
    <div>
      <span style={{ marginRight: '10px' }}>Document:</span>
      <label htmlFor="fileInput" style={{ cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>
        {selectedDocument ? selectedDocument.name : 'Télécharger un document'}
      </label>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleDocumentChange}
        accept={isResponsive ? "image/*" : undefined}
        capture={isResponsive ? "environment" : undefined}
      />
    </div>
  )
};

export default FileInput;
